
























































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import FormSwitch from '@/components/form/form-switch.vue';
import DatePicker from '@/components/form/date-picker.vue';
import btns from '@/components/form/police-btns.vue';
import { calculateClaimsRequirementPremium, onCurrencyFocus } from '@/helper/functions';
import xmlStatusIcon from '@/components/form/XML-status-icon.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

export default Vue.extend({
  name: 'price-card',
  mixins: [dataChangeMixin],
  components: {
    FormSwitch,
    DatePicker,
    btns,
    xmlStatusIcon,
  },
  props: [
    'id',
    'insuranceKey',
    'insuranceData',
    'productId',
    'AMSAPISendStatus',
  ],
  data(): any {
    return {
      backUrl: 'elektronik-insurance-risk',
      zusammenfassung: {
        versicherungsnehmer: '',
        betriebsart: '',
        versicherungssummeAktuell: '',
        versicherungssumme371: '',
        produkt: '',
        nettoisiert: '',
        steuerland: '',
        vorversicherer: '',
        versicherungsscheinnummerVorvertrag: '',
        PML: 0,
        bundelrabatt: '',
      },
      ergebnisseTarifkalkulationItems: {
        tarif: {
          pos: 'SB-Tarif SB',
          Selbstbehalt: '',
          TarifPramie: '',
          Dauernachlass: '',
          SVR: '',
          Nettoisierung: '',
          Pramie: '',
        },
        variant1: {
          pos: 'SB-Variante 1',
          Selbstbehalt: '',
          TarifPramie: '',
          Dauernachlass: '',
          SVR: '',
          Nettoisierung: '',
          Pramie: '',
        },
        variant2: {
          pos: 'SB-Variante 2',
          Selbstbehalt: '',
          TarifPramie: '',
          Dauernachlass: '',
          SVR: '',
          Nettoisierung: '',
          Pramie: '',
        },
        variant3: {
          pos: 'SB-Variante 3',
          Selbstbehalt: '',
          TarifPramie: '',
          Dauernachlass: '',
          SVR: '',
          Nettoisierung: '',
          Pramie: '',
        },
      },
      vorschadenhistorieSelect: false,
      vorschadenhistorie: {
        jahr1: {
          schaden: 0,
          reserven: 0,
        },
        jahr2: {
          schaden: 0,
          reserven: 0,
        },
        jahr3: {
          schaden: 0,
          reserven: 0,
        },
        jahr4: {
          schaden: 0,
          reserven: 0,
        },
        jahr5: {
          schaden: 0,
          reserven: 0,
        },
        summe: {
          schaden: 0,
          reserven: 0,
        },
        SBVorvertrag: '',
        Betrachtungszeitraum: '',
        Zielschadenquote: '',
        MindestjahrespramieTarifVariante: '',
        MindestjahrespramieSBVariante1: '',
        MindestjahrespramieSBVariante2: '',
        MindestjahrespramieSBVariante3: '',
        ZusatzlicherVorschadenfaktor: '',
        Begrundung: '',
      },
      ErgebnisRisikoadjustments: {
        Risikoadjustmentfaktor: '',
      },
      SkillNachlasseUndZuschlage: {
        Begrundung: '',
        items: {
          tarif: {
            pos: 'Skill-Nachlass Tarif Variante',
            Nachlasse: null,
            Zuschlage: null,
          },
          variant1: {
            pos: 'Skill-Nachlass SB-Variante 1',
            Nachlasse: null,
            Zuschlage: null,
          },
          variant2: {
            pos: 'Skill-Nachlass SB-Variante 2',
            Nachlasse: null,
            Zuschlage: null,
          },
          variant3: {
            pos: 'Skill-Nachlass SB-Variante 3',
            Nachlasse: null,
            Zuschlage: null,
          },
        },
      },
      vierAugenPrinzip: {
        show: false,
        discussedWith: '',
        date: '',
        notes: '',
      },
      promotionalRewards: {
        tarif: {
          pos: 'SB-Tarif SB',
          SB: '',
          PremiumIncludingTariffDiscounts: '',
          PremiumIncludingRiskAdjustmentFactor: '',
          PremiumIncludingMarketFactor: '',
          NetOfferPremium: '',
          NetOfferPremiumRate: '',
          GrossOfferPremium: '',
          PremiumNetRentalTurnover: '',
        },
        variant1: {
          pos: 'SB-Variante 1',
          SB: '',
          PremiumIncludingTariffDiscounts: '',
          PremiumIncludingRiskAdjustmentFactor: '',
          PremiumIncludingMarketFactor: '',
          NetOfferPremium: '',
          NetOfferPremiumRate: '',
          GrossOfferPremium: '',
          PremiumNetRentalTurnover: '',
        },
        variant2: {
          pos: 'SB-Variante 2',
          SB: '',
          PremiumIncludingTariffDiscounts: '',
          PremiumIncludingRiskAdjustmentFactor: '',
          PremiumIncludingMarketFactor: '',
          NetOfferPremium: '',
          NetOfferPremiumRate: '',
          GrossOfferPremium: '',
          PremiumNetRentalTurnover: '',
        },
        variant3: {
          pos: 'SB-Variante 3',
          SB: '',
          PremiumIncludingTariffDiscounts: '',
          PremiumIncludingRiskAdjustmentFactor: '',
          PremiumIncludingMarketFactor: '',
          NetOfferPremium: '',
          NetOfferPremiumRate: '',
          GrossOfferPremium: '',
          PremiumNetRentalTurnover: '',
        },
      },
      pdfStyles: false,
      hinweiseZumBeitrag: '',
    };
  },
  watch: {
    'insuranceData.insuranceFormsData.Praxispolice': function () {
      this.zusammenfassung.bundelrabatt = this.bundelrabattDefault;
    },
    'insuranceData.insuranceFormsData.ELEKPauschal': function () {
      this.zusammenfassung.bundelrabatt = this.bundelrabattDefault;
    },
  },
  computed: {
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
    prevTabItem(): any {
      return this.$store.getters['tabsModule/getLastTab']('insuranceForms');
    },
    hasELEKPauschal(): boolean {
      return (this.insuranceData.basicData.Produktauswahl.ELEKPauschal && this.insuranceData.insuranceFormsData !== null && this.insuranceData.insuranceFormsData.ELEKPauschal !== null);
    },
    hasPraxispolice(): boolean {
      return (this.insuranceData.basicData.Produktauswahl.Praxispolice && this.insuranceData.insuranceFormsData !== null && this.insuranceData.insuranceFormsData.Praxispolice !== null);
    },
    versicherungssummeElekPauschalNotDefault(): boolean {
      if (this.hasELEKPauschal) {
        const tabData = this.insuranceData.insuranceFormsData.ELEKPauschal;
        return (tabData.mitversicherteGerategruppen.Gesamtversicherungssumme.versicherungssumme !== 0 && (tabData.software.versicherungssumme !== 0 || tabData.mehrkostenErtragsausfall.versicherungssumme !== 0));
      }
      return false;
    },
    versicherungssummePraxispoliceNotDefault(): boolean {
      if (this.hasPraxispolice) {
        const tabData = this.insuranceData.insuranceFormsData.Praxispolice;
        return (tabData.mitversicherteGerategruppen.hardware.versicherungssumme !== 0 && (tabData.software.versicherungssumme !== 0 || tabData.mehrkostenErtragsausfall.versicherungssumme !== 0));
      }
      return false;
    },
    bundelrabattDefault(): number {
      if (this.versicherungssummeElekPauschalNotDefault || this.versicherungssummePraxispoliceNotDefault) {
        return 10;
      }
      return 0;
    },
    hasPriceFormData(): boolean {
      return (this.insuranceData.priceFormData !== null && this.insuranceData.priceFormData !== undefined);
    },
    vorschadenhistorieSummeSchaden(): number {
      return (+this.vorschadenhistorie.jahr1.schaden) +
        (+this.vorschadenhistorie.jahr2.schaden) +
        (+this.vorschadenhistorie.jahr3.schaden) +
        (+this.vorschadenhistorie.jahr4.schaden) +
        (+this.vorschadenhistorie.jahr5.schaden);
    },
    vorschadenhistorieSummeReserven(): number {
      return (+this.vorschadenhistorie.jahr1.reserven) +
        (+this.vorschadenhistorie.jahr2.reserven) +
        (+this.vorschadenhistorie.jahr3.reserven) +
        (+this.vorschadenhistorie.jahr4.reserven) +
        (+this.vorschadenhistorie.jahr5.reserven);
    },
    // Schadenbedarfsprämie
    claimsRequirementPremium(): number {
      return calculateClaimsRequirementPremium(
        this.vorschadenhistorieSummeSchaden,
        this.vorschadenhistorieSummeReserven,
        +this.vorschadenhistorie.Betrachtungszeitraum,
        +this.vorschadenhistorie.Zielschadenquote,
      );
    },
  },
  methods: {
    onCurrencyFocus,
    exportToPDF() {
      const filename = `Zusammenfassung-Elektronik-Preis-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1650,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    goToBackUrl() {
      if (this.prevTabItem) {
        this.$emit('go-back-tab', this.prevTabItem.path, 'insuranceForms', this.prevTabItem.key);
      } else {
        this.$emit('go-back-tab', 'elektronik-insurance', 'insuranceForms', 1);
      }
    },
    setValues(priceFormData: any) {
      this.zusammenfassung = priceFormData.zusammenfassung;
      this.ergebnisseTarifkalkulationItems = priceFormData.ergebnisseTarifkalkulationItems;
      this.vorschadenhistorieSelect = priceFormData.vorschadenhistorieSelect;
      this.vorschadenhistorie = priceFormData.vorschadenhistorie;
      this.ErgebnisRisikoadjustments = priceFormData.ErgebnisRisikoadjustments;
      this.SkillNachlasseUndZuschlage = priceFormData.SkillNachlasseUndZuschlage;
      this.vierAugenPrinzip = priceFormData.vierAugenPrinzip;
      this.vierAugenPrinzip.date = new Date(priceFormData.vierAugenPrinzip.date);
      this.promotionalRewards = priceFormData.promotionalRewards;
      this.hinweiseZumBeitrag = priceFormData.hinweiseZumBeitrag;
    },
    onNextClick(redirectToNextPage = true) {
      this.vorschadenhistorie.summe.schaden = this.vorschadenhistorieSummeSchaden;
      this.vorschadenhistorie.summe.reserven = this.vorschadenhistorieSummeReserven;
      this.vorschadenhistorie.MindestjahrespramieTarifVariante = this.claimsRequirementPremium;

      const data = {
        zusammenfassung: this.zusammenfassung,
        ergebnisseTarifkalkulationItems: this.ergebnisseTarifkalkulationItems,
        vorschadenhistorieSelect: this.vorschadenhistorieSelect,
        vorschadenhistorie: this.vorschadenhistorie,
        ErgebnisRisikoadjustments: this.ErgebnisRisikoadjustments,
        SkillNachlasseUndZuschlage: this.SkillNachlasseUndZuschlage,
        vierAugenPrinzip: this.vierAugenPrinzip,
        promotionalRewards: this.promotionalRewards,
        hinweiseZumBeitrag: this.hinweiseZumBeitrag,
      };
      this.$emit('price-formData-changed', data);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData.priceFormData) {
      this.setValues(this.insuranceData.priceFormData);
    }
    (this as any).startWatcherActivated();
  },
});
