/* eslint-disable */
// @ts-ignore
import { transformNumber } from '@/helper/functions';

function calculatePramieergebnisseTarifkalkulationItem(item: any) {
  const laufzeitCoeficient = (item.Laufzeit === 3) ? 0.9 : 1;
  let result = item.TarifPramie;
  result *= laufzeitCoeficient;
  result *= (1 - Number(item.SVR) / 100);
  result *= (1 - Number(item.Generalvertragsnachlasses) / 100);
  result *= (1 - Number(item.Nettoisierung) / 100);
  // eslint-disable-next-line no-param-reassign
  item.Pramie = transformNumber(result);
}

function getPramieergebnisseTarifkalkulationFactor(item: any) {
  const laufzeitCoeficient = (item.Laufzeit === 3) ? 0.9 : 1;
  let result = 1;
  result *= laufzeitCoeficient;
  result *= (1 - Number(item.SVR) / 100);
  result *= (1 - Number(item.Generalvertragsnachlasses) / 100);
  result *= (1 - Number(item.Nettoisierung) / 100);
  // eslint-disable-next-line no-param-reassign
  return result;
}

function calculatePramieTableItem(item: any, risikofactor: number, discountsBonus: number, zusatzlicherVorschadenfaktorСoefficient: number, versicherungssummeAktuell: number) {
  item.PremiumIncludingRiskAdjustmentFactor = transformNumber(item.PremiumIncludingTariffDiscounts * risikofactor);
  item.PremiumIncludingMarketFactor = transformNumber(item.PremiumIncludingRiskAdjustmentFactor * discountsBonus);
  item.NetOfferPremium = transformNumber(item.PremiumIncludingMarketFactor * zusatzlicherVorschadenfaktorСoefficient);
  if (versicherungssummeAktuell > 0) {
    item.NetOfferPremiumRate = transformNumber((item.NetOfferPremium / versicherungssummeAktuell) * 1000);
  }
  item.GrossOfferPremium = item.NetOfferPremium * 1.19;

}
function getDiscountsBonuses(discountsBonusesItem: any): number {
  let result = 1;
  result *= (1 - Number(discountsBonusesItem.Nachlasse)/100);
  result *= (1 + Number(discountsBonusesItem.Zuschlage)/100);
  return result;
}

export {
  calculatePramieergebnisseTarifkalkulationItem,
  calculatePramieTableItem,
  getDiscountsBonuses,
  getPramieergebnisseTarifkalkulationFactor
};
