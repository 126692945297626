<template>
  <table class="positionsdaten-table td-150 w-100">
    <tr>
      <th class=" border-right ">Pos.</th>
      <th class="b-non">Selbstbehalt</th>
      <th class="">Prämie inkl. tariflicher Nachlässe</th>
      <th class="">Prämie inkl. Risikoadjustmentfaktor</th>
      <th class="">Prämie inkl. Marktfaktor</th>
      <th class="">Angebotsprämie netto</th>
      <th class="">Angebotsprämiensatz netto</th>
      <th class="">Angebotsprämie brutto</th>
    </tr>
    <template v-for="(item, i) in dataArray">
      <tr :key="i" :class="deductibleVariantsCssClasses[i]">
        <td class=" border-right text-left">
          <b>{{ item.pos }}</b>
        </td>
        <td>
          <currency-input
            class="form-control w-100 text-right"
            :precision="2"
            v-model.number="item.SB"
            :name="`${arrayName}-SB${i}`"
            :id="`${arrayName}-SB${i}`"
            @focus="onCurrencyFocus"
            @click="onCurrencyFocus"
            @input="updateValue(i, 'SB', $event)"
          />
        </td>
        <td class="">
          <currency-input
            class="form-control text-right"
            :precision="2"
            :disabled="true"
            v-model.number="item.PremiumIncludingTariffDiscounts"
            @input="updateValue(i, 'PremiumIncludingTariffDiscounts', $event)"
          />
        </td>
        <td class="">
          <currency-input
            class="form-control text-right"
            :precision="2"
            :disabled="true"
            v-model.number="item.PremiumIncludingRiskAdjustmentFactor"
            @input="updateValue(i, 'PremiumIncludingRiskAdjustmentFactor', $event)"
          />
        </td>
        <td class="">
          <currency-input
            class="form-control text-right"
            :precision="2"
            :disabled="true"
            v-model.number="item.PremiumIncludingMarketFactor"
            @input="updateValue(i, 'PremiumIncludingMarketFactor', $event)"
          />
        </td>
        <td class="">
          <currency-input
            class="form-control text-right"
            :precision="2"
            :disabled="true"
            v-model.number="item.NetOfferPremium"
            @input="updateValue(i, 'NetOfferPremium', $event)"
          />
        </td>
        <td class="">
          <currency-input
            class="form-control text-right"
            :precision="2"
            :disabled="true"
            v-model.number="item.NetOfferPremiumRate"
            :currency="{ prefix: '', suffix: ' ‰' }"
            @input="updateValue(i, 'NetOfferPremiumRate', $event)"
          />
        </td>
        <td class="">
          <currency-input
            class="form-control text-right"
            :precision="2"
            :disabled="true"
            v-model.number="item.GrossOfferPremium"
            @input="updateValue(i, 'GrossOfferPremium', $event)"
          />
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import { onCurrencyFocus } from '@/helper/functions';

export default {
  name: 'pramie-table',
  props: {
    dataArray: Array, // The array to be displayed
    arrayName: String, // A unique identifier for the array
  },
  data() {
    return {
      deductibleVariantsCssClasses: {
        0: '',
        1: 'table-variant-1',
        2: 'table-variant-2',
        3: 'table-variant-3',
      },
    };
  },
  methods: {
    onCurrencyFocus,
    updateValue(index, key, value) {
      // Create a copy of the item at the specified index with the updated value
      const updatedItem = {
        ...this.dataArray[index], [key]: value,
      };

      // Create a new array with the updated item
      const updatedArray = [...this.dataArray];
      updatedArray[index] = updatedItem;

      // Emit an event to notify the parent component of the updated array
      this.$emit('update-item', {
        arrayName: this.arrayName, updatedArray,
      });
    },
  },
};
</script>


<style scoped>

.table-variant-1 {
  background-color: #d3ffe2 !important;
}
.table-variant-2 {
  background-color: #dbeefc !important;
}
.table-variant-3 {
  background-color: #f1ffd1 !important;
}
.positionsdaten-table th {
  white-space: normal !important;
}
</style>
