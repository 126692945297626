<template>
  <div class="d-inline-block">
    <div v-if="XMLStatus === null" class="check-icon not-selected">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path fill="gray" d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
      </svg>
    </div>
    <div v-else-if="statusSuccess" class="check-icon selected">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
        <path fill="green" d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
      </svg>
    </div>
    <div v-else @click="showError(XMLStatus)" class="check-icon error">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill="red" d="M12 1C6.48 1 2 5.48 2 11C2 16.52 6.48 21 12 21C17.52 21 22 16.52 22 11C22 5.48 17.52 1 12 1ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"/>
      </svg>
    </div>
    <popUpModal v-if="modalOpen" :title="modalTitle" :body="modalBody" @modal_close="modalClose"></popUpModal>
  </div>
</template>

<script>
import popUpModal from '@/components/form/pop-up-modal.vue';

export default {
  name: 'XML-status-icon',
  components: {
    popUpModal,
  },
  props: ['XMLStatus'],
  data() {
    return {
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
    };
  },
  methods: {
    openModal(title, body) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    showError(XMLStatus) {
      if (!XMLStatus.statusSuccess && typeof XMLStatus.statusErrorMessage === 'string') {
        this.openModal('error', XMLStatus.statusErrorMessage);
      }
      if (!XMLStatus.statusSuccess && Array.isArray(XMLStatus.statusErrorMessage)) {
        let messages = '';

        XMLStatus.statusErrorMessage.forEach((element, index) => {
          messages = `${messages + element} `;
        });
        this.openModal('errors', messages);
      }
    },
  },
  computed: {
    statusSuccess() {
      return this.XMLStatus?.statusSuccess;
    },
  },
};
</script>
