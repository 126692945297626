/* eslint-disable */
// @ts-ignore

import {transformNumber} from "@/helper/functions";

function calculatePramienSelbstbehaltsvariantenItems(pramienSelbstbehaltsvariantenItems: any) {
    calculatePramieergebnisseTarifkalkulationItem(pramienSelbstbehaltsvariantenItems.tarif);
    calculatePramieergebnisseTarifkalkulationItem(pramienSelbstbehaltsvariantenItems.SBVariante1);
    calculatePramieergebnisseTarifkalkulationItem(pramienSelbstbehaltsvariantenItems.SBVariante2);
    calculatePramieergebnisseTarifkalkulationItem(pramienSelbstbehaltsvariantenItems.SBVariante3);
}

function calculatePramieergebnisseTarifkalkulationItem(item: any) {
  const laufzeitCoeficient = (item.laufzeit === 3) ? 0.9 : 1;
  let result = item.tarifPramie;
  result *= laufzeitCoeficient;
  result *= (1 - Number(item.SVR) / 100);
  result *= (1 - Number(item.nettoisierung) / 100);
  result *= (1 - Number(item.kurzfristige) / 100);
  // eslint-disable-next-line no-param-reassign
  item.pramieInklTariflicherNachlasse = transformNumber(result);
}
function calculatePramieergebnisseTarifkalkulationDiscounts(PremiumIncludingTariffDiscounts: number, PramieergebnisseTarifkalkulationItem: any) {
  const laufzeitCoeficient = (PramieergebnisseTarifkalkulationItem.laufzeit === 3) ? 0.9 : 1;
  let result = PremiumIncludingTariffDiscounts;
  result *= laufzeitCoeficient;
  result *= (1 - Number(PramieergebnisseTarifkalkulationItem.SVR) / 100);
  result *= (1 - Number(PramieergebnisseTarifkalkulationItem.nettoisierung) / 100);
  result *= (1 - Number(PramieergebnisseTarifkalkulationItem.kurzfristige) / 100);
  // eslint-disable-next-line no-param-reassign
  return transformNumber(result);
}
function getDiscountsBonuses(skillDiscounts) {
  const resultData: Array<number> = [];
  resultData.push(
    getDiscountsBonuse(skillDiscounts.skillDiscountTariffVariant),
  );
  resultData.push(
    getDiscountsBonuse(skillDiscounts.skillDiscountSBvariant1),
  );
  resultData.push(
    getDiscountsBonuse(skillDiscounts.skillDiscountSBvariant2),
  );
  resultData.push(
    getDiscountsBonuse(skillDiscounts.skillDiscountSBvariant3),
  );
  return resultData;
}
function getDiscountsBonuse(discountsBonusesItem: any): number {
  let result = 1;
  result *= (1 - Number(discountsBonusesItem.estates)/100);
  result *= (1 + Number(discountsBonusesItem.surcharges)/100);
  return result;
}

function calculatepromotionalRewards(promotionalRewards: any, skillDiscounts: any, zusatzlicherVorschadenfaktorСoefficient: number, versicherungssummeAktuell: number) {

  const discountsBonuses = getDiscountsBonuses(skillDiscounts);
  const risikofactor = Number(skillDiscounts.resultRiskFactors);

  promotionalRewards.forEach((item: any, index) => {
    calculatepromotionalRewardItem(item, risikofactor, discountsBonuses[index], zusatzlicherVorschadenfaktorСoefficient, versicherungssummeAktuell)
  });
}
function calculatepromotionalRewardItem(pramienSelbstbehaltsvariantenItem: any, risikofactor: number, discountsBonus: number, zusatzlicherVorschadenfaktorСoefficient: number, versicherungssummeAktuell: number) {
  pramienSelbstbehaltsvariantenItem.PremiumIncludingRiskAdjustmentFactor = transformNumber(pramienSelbstbehaltsvariantenItem.PremiumIncludingTariffDiscounts * risikofactor);
  pramienSelbstbehaltsvariantenItem.PremiumIncludingMarketFactor = transformNumber(pramienSelbstbehaltsvariantenItem.PremiumIncludingRiskAdjustmentFactor * discountsBonus);
  pramienSelbstbehaltsvariantenItem.NetOfferPremium = transformNumber(pramienSelbstbehaltsvariantenItem.PremiumIncludingMarketFactor * zusatzlicherVorschadenfaktorСoefficient);
  if (versicherungssummeAktuell > 0) {
    pramienSelbstbehaltsvariantenItem.NetOfferPremiumRate = transformNumber((pramienSelbstbehaltsvariantenItem.NetOfferPremium / versicherungssummeAktuell) * 1000);
  }
  pramienSelbstbehaltsvariantenItem.GrossOfferPremium = pramienSelbstbehaltsvariantenItem.NetOfferPremium * 1.19;
}
function getVersicherungssummeAktuell(positionsdatenItems: any) {
  let result = 0;
  positionsdatenItems.forEach((item: any, index) => {
    result += item.Versicherunswert;
  });
  return result;
}
function fillErgebnisseTarifkalkulationPramie(priceResponseData, spreadsheetData, basicData, priceData) {
  const positionsdatenItems = spreadsheetData.positionsdatenItems;
  const vertragsbezogeneZuschlageItems = spreadsheetData.vertragsbezogeneZuschlageItems;
  const pramienSelbstbehaltsvariantenItems = spreadsheetData.pramienSelbstbehaltsvariantenItems;

  const selbstbehaltValues = getSelbstbehaltValues(pramienSelbstbehaltsvariantenItems, positionsdatenItems);
  setSelbstbehaltValues(priceData.pramienSelbstbehaltsvariantenItems, selbstbehaltValues);

  ['tarif', 'variant1', 'variant2', 'variant3'].forEach((variant, index) => {
    if (checkIfPriceDataHaveResponse(priceResponseData, variant)) {
      const PositionsdatenSumm = getPramiePositionsdatenSumm(
        priceResponseData[variant].priceResponse.Result.Maschinen,
        positionsdatenItems,
        basicData.vertragsart,
        vertragsbezogeneZuschlageItems
      );
      const PramieERSBruttoFactor = Number(priceResponseData[variant].priceResponse.Result['Prämienübersicht']['Prämie_ERS_Brutto']);

      setPremiumValues(priceData, PositionsdatenSumm, PramieERSBruttoFactor, index);
      priceData.pramienSelbstbehaltsvariantenItems[getVariantKey(index)].tarifPramie = PositionsdatenSumm + PramieERSBruttoFactor;
    }
  });

  const zusatzlicherVorschadenfaktorCoefficient = (100 - priceData.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100;

  calculatePromotionalRewards(priceData, zusatzlicherVorschadenfaktorCoefficient);
  calculatePramienSelbstbehaltsvariantenItems(priceData.pramienSelbstbehaltsvariantenItems);
}

function getSelbstbehaltValues(pramienSelbstbehaltsvariantenItems, positionsdatenItems) {
  return {
    tarif: pramienSelbstbehaltsvariantenItems.tarif.SB,
    variant1: pramienSelbstbehaltsvariantenItems.variant1.SB,
    variant2: pramienSelbstbehaltsvariantenItems.variant2.SB,
    variant3: getSBPositionsdatenSumm(positionsdatenItems)
  };
}

function setSelbstbehaltValues(pramienSelbstbehaltsvariantenItems, values) {
  pramienSelbstbehaltsvariantenItems.tarif.selbstbehalt = values.tarif;
  pramienSelbstbehaltsvariantenItems.SBVariante1.selbstbehalt = values.variant1;
  pramienSelbstbehaltsvariantenItems.SBVariante2.selbstbehalt = values.variant2;
  pramienSelbstbehaltsvariantenItems.SBVariante3.selbstbehalt = values.variant3;
}

function setPremiumValues(priceData, PositionsdatenSumm, PramieERSBruttoFactor, index) {
  const variantKey = getVariantKey(index);
  priceData.maschinenItems[index].SB = priceData.pramienSelbstbehaltsvariantenItems[variantKey].selbstbehalt;
  priceData.erstrisikosummenItems[index].SB = priceData.pramienSelbstbehaltsvariantenItems[variantKey].selbstbehalt;
  priceData.promotionalRewards[index].SB = priceData.pramienSelbstbehaltsvariantenItems[variantKey].selbstbehalt;

  priceData.maschinenItems[index].PremiumIncludingTariffDiscounts = calculatePramieergebnisseTarifkalkulationDiscounts(PositionsdatenSumm, priceData.pramienSelbstbehaltsvariantenItems[variantKey]);
  priceData.erstrisikosummenItems[index].PremiumIncludingTariffDiscounts = calculatePramieergebnisseTarifkalkulationDiscounts(PramieERSBruttoFactor, priceData.pramienSelbstbehaltsvariantenItems[variantKey]);
  priceData.promotionalRewards[index].PremiumIncludingTariffDiscounts = priceData.maschinenItems[index].PremiumIncludingTariffDiscounts + priceData.erstrisikosummenItems[index].PremiumIncludingTariffDiscounts;
}

function getVariantKey(index) {
  const keys = ['tarif', 'SBVariante1', 'SBVariante2', 'SBVariante3'];
  return keys[index];
}

function calculatePromotionalRewards(priceData, coefficient) {
  const versicherungssummeAktuell = priceData.zusammenfassung.versicherungssummeAktuell;
  calculatepromotionalRewards(priceData.maschinenItems, priceData.skillDiscounts, coefficient, versicherungssummeAktuell);
  calculatepromotionalRewards(priceData.erstrisikosummenItems, priceData.skillDiscounts, coefficient, versicherungssummeAktuell);
  calculatepromotionalRewards(priceData.promotionalRewards, priceData.skillDiscounts, coefficient, versicherungssummeAktuell);
}

function getSBPositionsdatenSumm(positionsdatenItems){
  let summ = 0;

  positionsdatenItems.forEach((item: any, index) => {
    summ += Number(item.SB);
  });
  return summ;
}

function getPramiePositionsdatenSumm(Maschinen, positionsdatenItems, vertragsart, vertragsbezogeneZuschlageItems) {
  let summ = 0;

  positionsdatenItems.forEach((item: any, index) => {
    let result = getPramiePositionsdatenItem(Maschinen[index]['Prämie_SB_Var1_Maschine'], item, vertragsart, vertragsbezogeneZuschlageItems);
    summ += result;
  });
  return summ
}

function getPramiePositionsdatenItem(pramie, positionsdatenItem, vertragsart, vertragsbezogeneZuschlageItems) {
  const spindelklauselFactor = getSpindelklauselFactor(positionsdatenItem.Spindelklausel);
  const vermietungUndVerpachtungFactor = getVermietungUndVerpachtungFactor(positionsdatenItem.VermietungUndVerpachtung);
  const vertragsartFactor = getVertragsartFactor(vertragsart, positionsdatenItem.GAPDeckung);
  const unterschlagungFOTGerateFactor = getUnterschlagungFOTGerateFactor(positionsdatenItem.UnterschlagungFOTGerate);
  const mitversicherungInnereUnruhenFactor = getMitversicherungInnereUnruhen(vertragsbezogeneZuschlageItems[0].MitversicherungInnereUnruhen);

  let result = Number(pramie) * (1 - Number(positionsdatenItem.Neuheitsrabatt) / 100);
  result *= Number(spindelklauselFactor);
  result *= (1 + Number(positionsdatenItem.StationareMaschinenMobilEingesetztPercent) / 100);
  result *= (1 + Number(positionsdatenItem.MitversicherungFeuerInMASS) / 100);
  result *= (1 + Number(positionsdatenItem.MitversicherungUberschwemmungInMASS) / 100);
  result *= (1 + Number(positionsdatenItem.MitversicherungErdbebenInMASS) / 100);
  result *= (1 - Number(positionsdatenItem.AusschlussKraftKaskoRisiko) / 100);
  result *= (1 + Number(positionsdatenItem.EinsatzImAusland) / 100);
  result *= (1 + Number(positionsdatenItem.SchadenDurchVuV) / 100);
  result *= (1 + Number(positionsdatenItem.EinsatzUnterTage) / 100);
  result *= (1 - Number(positionsdatenItem.individualDiscount) / 100);
  result *= (1 + Number(positionsdatenItem.individualSurcharge) / 100);
  result *= Number(vermietungUndVerpachtungFactor);
  result *= Number(vertragsartFactor);
  result *= Number(unterschlagungFOTGerateFactor);
  result *= (1 + Number(vertragsbezogeneZuschlageItems[0].Regressverzicht) / 100);
  result *= Number(mitversicherungInnereUnruhenFactor);

  return result;
}

function getSpindelklauselFactor(Spindelklausel): number {
  if (Spindelklausel) {
    return 0.8;
  }
  return 1;
}
function getVermietungUndVerpachtungFactor(vermietungUndVerpachtung): number {
  if (vermietungUndVerpachtung === 'Verleih an Mieter ohne geschultes') {
    return 1.15;
  }
  return 1;
}
function getVertragsartFactor(vertragsart, GAPDeckung): number {
  if (vertragsart === 'MaschineFahrbar' && GAPDeckung) {
    return 1.05;
  }
  return 1;
}
function getUnterschlagungFOTGerateFactor(UnterschlagungFOTGerate): number {
  if (UnterschlagungFOTGerate) {
    return 1.05;
  }
  return 1;
}
function getMitversicherungInnereUnruhen(MitversicherungInnereUnruhen): number {
  if (MitversicherungInnereUnruhen) {
    return 1.03;
  }
  return 1;
}
function checkIfPriceDataHaveResponse(priceData, key: string): boolean {
  if (typeof priceData[key].priceResponse !== 'object' || Object.keys(priceData[key].priceResponse).length === 0) {
    return false;
  }
  return true;
}
function fillCalcPriceResponse(positionsdatenItems, newVal, beitragIndex, beitragFactorIndex, vertragsart, vertragsbezogeneZuschlageItem) {
  if (typeof newVal.priceResponse !== 'object' || Object.keys(newVal.priceResponse).length === 0) {
    return false;
  }
  positionsdatenItems.forEach((element: any, index) => {
    element.Pramiensatz = newVal.priceResponse.Result.Maschinen[index]['Faktor_Prämiensatz'];
    element.VSU = Number((Number(element.Versicherunswert) / Number(newVal.priceResponse.Result.Maschinen[index].Summenfaktor)).toFixed(0));
    element.Faktor = newVal.priceResponse.Result.Maschinen[index]['Prämienfaktor'];
    element[beitragIndex] = getPramiePositionsdatenItem(newVal.priceResponse.Result.Maschinen[index]['Prämie_SB_Var1_Maschine'], element, vertragsart, vertragsbezogeneZuschlageItem);
    // element[beitragIndex] = newVal.priceResponse.Result.Maschinen[index]['Prämie_SB_Var1_Maschine'];
    element[beitragFactorIndex] = newVal.priceResponse.Result.Maschinen[index].SB_Rabatt_Faktor_1;
  });
}
export {
  fillErgebnisseTarifkalkulationPramie,
  fillCalcPriceResponse,
  calculatePramienSelbstbehaltsvariantenItems,
  getVersicherungssummeAktuell,
};
