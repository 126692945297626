













import Vue from 'vue';
import apiBackend from '@/service/api-insurance';
import { WEBSITE_PUBLIC_URL } from '@/constant/constants';

export default Vue.extend({
  name: 'btns',
  props: ['id', 'active'],
  watch: {
  },
  methods: {
    // save() {
    //   apiBackend.sendBigXML(this.id);
    // },
    sendBigXML() {
      apiBackend.sendBigXML(this.id);
    },
    downloadBigXML() {
      apiBackend.downloadBigXML(this.id).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'big_xml.xml');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    downloadPDF() {
      apiBackend.downloadPDF(this.id).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Vorschlag-${this.id}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    openPDF() {
      window.location.href = `${WEBSITE_PUBLIC_URL}/api/pdfDebugging/${this.id}/vorschlag`;
    },
  },
  data() {
    return {
      vertragsdatenOpen: true,
    };
  },
});
